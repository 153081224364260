@font-face {
    font-family: "Elementary Gothic Bookhand";
    src: url('../fonts/ElementaryGothic/elementary_gothic_bookhand-webfont.woff') format('woff');
    src: url('../fonts/ElementaryGothic/elementary_gothic_bookhand-webfont.woff2') format('woff2');
    src: url('../fonts/ElementaryGothic/Elementary_Gothic_Bookhand.ttf') format('truetype');
}

@font-face {
    font-family: "FS Rosa";
    src: url('../fonts/FSRosa/fs_rosa_regular-webfont.woff') format('woff');
    src: url('../fonts/FSRosa/fs_rosa_regular-webfont.woff2') format('woff2');
    src: url('../fonts/FSRosa/FS\ Rosa\ Regular.ttf') format('truetype');
}

body,
html {
    height: 100%;
    width: 100%
}

html,
body {
    max-width: 100%;
}

.navbar-custom,
body {
    background-color: #1a1d16;
}

.intro,
body,
html {
    width: 100%
}

body {
    font-family: "FS Rosa";
    color: #fff;
}

h1 {
    font-family: "Elementary Gothic Bookhand";
    letter-spacing: -5px;
    font-weight: 100;
    font-size: 6rem;
}

.btn,
.navbar-custom,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Times New Roman"
}

h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 35px;
    font-weight: 700;
    letter-spacing: 1px
}



#mastheadText {
    font-family: "FS Rosa";
    font-weight: 2;
    font-size: 80px;
    letter-spacing: -7px;
    line-height: 60px;
    text-align: right;

}

@media (max-height:700px) {
    #mastheadText {
        font-size: 70px;
    }
}

#mastheadTextContainer {
    transform: scaleY(1.3);
}

.mastheadButton {
    margin-top: 20px;
    float:right;
}

#mastheadGlyph {
    font-size: 33px;
    color: #fff;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all 0.2s ease-in-out;
}


#mastheadGlyph:hover {
    opacity: 60%;
    transform: translateY(10px);
}


.mastheadContent {
    padding-top: 100px;
}

.socialIcon {
    font-size: 20px;
}

.socialLI {
    margin-right: 10px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.socialLI:hover {
    transform: scale(0.9);
    opacity: 60%;
}

p {
    margin: 0 0 25px;
    font-size: 18px;
    line-height: 1.5
}

@media (min-width:768px) {
    p {
        margin: 0 0 35px;
        font-size: 20px;
        line-height: 1.6
    }
}

a {
    color: #939393;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    text-decoration: underline;
}

a:focus,
a:hover {
    color: #505050
}

a.iconLink {
    color: #ffffff;
    opacity: 1;
}


.light {
    font-weight: 400
}

.navbar-custom {
    margin-bottom: 0;
}

.navbar-custom .navbar-toggle {
    color: #fff;
    font-size: 18px
}

.navbar-custom .nav li a {
    text-decoration: none;
}

.navbar-custom .nav li a:active,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li a:hover {
    background-color: transparent;
    outline: 0;
}

.navbar-custom .navbar-toggle:active,
.navbar-custom .navbar-toggle:focus {
    outline: 0
}

.navbar-holder {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

@media (max-width:991px) {

    .mastheadButton {
        margin-bottom: 60px;
    }

}

.navbar-shift {
    font-size: 13px;
    padding-left: 0px;
    padding-right: 0px;
}

.nav-links{
    font-size:17px;
    margin-right: 7px;
}

.nav-links:hover{
    opacity: 80%;
}

.navbar-custom .navbar-brand:focus {
    outline: 0
}

.stylized-text{
    font-family: "Elementary Gothic Bookhand";
    text-decoration: none;
}

.stylized-text:hover {
    opacity: 60%;
    text-decoration: none;
}

.navbar-custom a {
    color: #fff
}

.navbar-custom .nav li a {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
}

.navbar-custom .nav li a:hover {
    color: rgba(255, 255, 255, .8)
}

.navbar-custom .nav li.active {
    outline: 0
}

.navbar-custom .nav li.active a {
    background-color: rgba(255, 255, 255, .3)
}

.navbar-custom .nav li.active a:hover {
    color: #fff
}

@media (min-width:768px) {
    .navbar-custom {
        padding-top: 100px;
        padding-bottom: 15px;
        border-bottom: none;
        letter-spacing: 1px;
        background: 0 0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .navbar-custom.top-nav-collapse {
        padding-top: 25px;
        background: #1a1d16;
        border-bottom: none;
    }
}

@media (max-width:768px) {
    .navbar-custom {
        padding: 30px 30px;
        border-bottom: none;
        letter-spacing: 1px;
        background: 0 0;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .navbar-custom.top-nav-collapse {
        padding: 10px 30px;
        background: #1a1d16;
        border-bottom: none;
    }

    .banner-social-buttons{
        text-align: center;
    }
}

.intro {
    display: table;
    height: auto;
    padding: 100px 0;
    text-align: center;
    position: relative;
    color: #fff;
}

.introbg {
    opacity: 0.75;
    background: url(../img/full.jpg) bottom center no-repeat #000;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.introgradient {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #1a1d16);
    position: absolute;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

@media (min-width:0px) {
    .introgradient {
        top: 300px;
    }

}

@media (min-width:768px) {
    .introgradient {
        top: 700px;
    }

}

.intro .intro-body .intro-text {
    font-size: 18px
}

.intro-body{
    margin-top: 200px;

}

@media (min-width:0px) {
    .intro {
        height: 80%;
    }
}

@media (min-width:768px) {
    .intro {
        height: 100%;
        padding: 0
    }
}

.btn-circle {
    width: 70px;
    height: 70px;
    margin-top: 15px;
    padding: 7px 16px;
    border: 2px solid #fff;
    border-radius: 100% !important;
    font-size: 40px;
    color: #fff;
    background: 0 0;
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out
}

.btn-circle:focus,
.btn-circle:hover {
    outline: 0;
    color: #fff;
    background: rgba(255, 255, 255, .1)
}


.content-section {
    padding-top: 200px;
}

#map {
    width: 100%;
    height: 200px;
    margin-top: 100px
}

@media (min-width:0px) {
    .content-section {
        padding-top: 150px
    }

    #map {
        height: 400px;
        margin-top: 250px
    }
}

@media (min-width:768px) {
    .content-section {
        padding-top: 200px
    }

    #map {
        height: 400px;
        margin-top: 250px
    }
}

.btn {
    font-weight: 400;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0px;
    text-decoration: none;
}

.btnAction {
    font-size: 16px;
}

span.btnText {
    padding: 20px;
}

.btn-default {
    border: 1px solid #ffffff;
    outline: 0;
    color: #ffffff;
    transition: 0.3s ease;
    background-color: transparent;
    border-radius: 3px;
    opacity: 1;
}

.btn-default:focus,
.btn-default:hover {
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
    border-radius: 20px;
    transform: scale(0.9, 0.9);
    opacity: 0.8;
}

@media (max-width:991px) {
    ul.banner-social-buttons li {
        padding: 0;
    }
}

footer {
    margin-top: 300px;
    padding: 50px 0;
}

footer p {
    margin: 0
}

::-moz-selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2)
}

::selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(255, 255, 255, .2)
}

img::selection {
    background: 0 0
}

img::-moz-selection {
    background: 0 0
}

.featuredContainer {
    margin-bottom: 40px;
    min-height: 500px;

}

.featureheader {
    font-family: "FS Rosa";
    letter-spacing: -1px;
    margin-bottom: 10px;
}

.featuresubtitle {
    font-size: 18px;
    opacity: 80%;
    margin-top: 0;
    margin-bottom: 0;
}

.featuresubtitle2 {
    font-size: 15px;
    opacity: 80%;
    margin-top: 0;
    margin-bottom: 0;
}

.featuredesc {
    text-align: left;
}

.featuredpicture {

    width: 20rem;
    margin-bottom: 20px;
    position: relative;
    opacity: 1;
    transition: 0.3s ease;
    cursor: pointer;
    border-radius: 5px;
}

.featuredpicture:hover {
    transform: scale(0.95, 0.95);
    opacity: 0.6;
    border-radius: 40px;
}

.aboutVideoContainer {
    width: 100%;
    height: 50rem;
}

.aboutVideo {
    border-radius: 20px;
    object-fit: cover;
    object-position: right top;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}

.PerformancesimgContainer {
    width: 100%;
    height: 50rem;
}

.Performancesimg {
    border-radius: 20px;
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}

.sectionHeading {
    padding-top: 40px;
    font-family: "FS Rosa";
    letter-spacing: -1px;
    font-size: 50px;
}

.aboutText {
    padding-top: 0px;
}

.moveup {
    margin-top: -100px;
}

.intro-parallax {
    min-height: 1000px;
}

.expanderText {
    font-size: 14px;
}


.showmore {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.vanish {
    opacity: 0;
}

.collapseAnchor {
    height: 150px;
    margin-top: -150px;
    display: block;
    position: absolute;
    visibility: hidden;
}

#releasesAnchor {
    height: 80px;
    margin-top: -80px;
    display: block;
    position: absolute;
    visibility: hidden;
}

#topAnchor {
    height: 80px;
    margin-top: -200px;
    display: block;
    position: absolute;
    visibility: hidden;
}

#performancesAnchor {
    height: 80px;
    margin-top: -80px;
    display: block;
    position: absolute;
    visibility: hidden;
}

#aboutAnchor {
    height: 80px;
    margin-top: -80px;
    display: block;
    position: absolute;
    visibility: hidden;
}

#archiveAnchor {
    height: 80px;
    margin-top: -80px;
    display: block;
    position: absolute;
    visibility: hidden;
}

.productionsCaption {
    font-size: 18px;
    opacity: 80%;
    margin-top: 0;
}

.productionTitle {
    font-family: "FS Rosa";
    letter-spacing: -1px;
    margin: 0;
}

.performancesRow {
    transition: 0.3s ease;
    border-bottom: 1px solid #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.performancesRow:hover {
    background-color: #ffffff06;
}

.performancesDate {
    text-transform: uppercase;
    margin-bottom: 0;
}

.performancesLocHead {
    font-size: 18px;
    margin: 0;
}

.performancesLoc1 {
    font-size: 16px;
    margin: 0;
}

.performancesLoc2 {
    opacity: 0.8;
    font-size: 16px;
    margin-bottom: 5px;
}
/*
@media (max-width:768px) {
    #performanceSection .btn{
        width: 20px;
        text-align: left;
    }
}
    */

.footerText {
    font-family: "Times New Roman";
    font-size: 13px;
    text-align: left;
}

.footerImage {
    width: 70px;
    float: right;
}

.archive-section {
    padding-top: 100px;
    padding-bottom: 200px;
}

.archiveMasterList {
    margin: 0;
    padding: 0;
}

.archiveMasterList li {
    list-style-type: none;
}

.archiveMasterListLink {
    font-family: "FS Rosa";
    font-weight: 100;
    letter-spacing: -2px;
    line-height: 100%;
    font-size: 40px;
}

.archiveMasterListCaption {
    font-size: 18px;
    opacity: 80%;
}

.expandLink {
    color: white;
    text-decoration: none;
}

.expandLink:hover {
    color: white;
    text-decoration: none;
    opacity: 60%;
}

.archiveGlyph {
    font-size: 30px;
    padding-left: 10px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.archiveGlyphMove {
    transform: translateX(10px);
}

.archiveGrid li {
    display: inline;
}

.archiveGrid a {
    text-decoration: none;
}

.archiveGrid {
    padding-bottom: 40px;
}

.collapse {
    opacity: 100%;
    transform: scale(1.0, 1.0);

    -webkit-transition: all 0.6s ease-in-out;
    -moz-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}

.collapsing {
    opacity: 0;
}

.archiveClose {
    font-size: 20px;
    margin-right: 87px;
    margin-bottom: 10px;
    float: right;
    color: white;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.archiveClose:hover {
    transform: scale(1.3, 1.3);
    opacity: 0.5;
}

.archiveGridItem {
    position: relative;
    display: inline-block;
    opacity: 1;
    transition: 0.3s ease;
    cursor: pointer;
}

.archiveGridItem:hover {
    transform: scale(1.1, 1.1);
    opacity: 0.5;
}

.archiveGridTitle {
    color: white;
    font-size: 18px;
    letter-spacing: -1px;
    margin-top: 5px;
    margin-bottom: 0;
    opacity: 80%;
}

.archiveGridCaption {
    color: white;
    font-size: 17px;
    opacity: 80%;
}

.archiveGridItemMusic {
    width: 23rem;
    margin: 10px;
}

.archiveMusicImage {
    width: 23rem;
}

.archiveGridItemYouTube {
    width: 50rem;
    height: 55rem;
    margin: 10px;
}

.archiveYouTubeImage {
    width: 50rem;
    padding-bottom: 10px;
}


@media (max-width:604px) {
    .archiveGridItemYouTube {
        width: 30rem;
        height: 30rem;
        margin: 10px;
    }

    .archiveYouTubeImage {
        width: 30rem;
        padding-bottom: 10px;
    }

}


.archiveGridItemBlog {
    width: 50rem;
    height: 50rem;
    margin: 13px;
}

.archiveBlogImage {
    width: 50rem;
    padding-bottom: 10px;
}

@media (max-width:610px) {
    .archiveGridItemBlog {
        width: 30rem;
        height: 30rem;
        margin: 13px;
    }

    .archiveBlogImage {
        width: 30rem;
        padding-bottom: 10px;
    }
}

.archiveGridItemWebsite {
    width: 100rem;
}

.archiveGridItemWebsite:hover {
    transform: scale(1.0, 1.0);
    opacity: 1;
}

.archiveWebsiteImage {
    width: 100rem;
}

@media (max-width:1199px) {
    .archiveGridItemWebsite {
        width: 70rem;
    }

    .archiveWebsiteImage {
        width: 70rem;
    }
}

@media (max-width:810px) {
    .archiveGridItemWebsite {
        width: 50rem;
    }

    .archiveWebsiteImage {
        width: 50rem;
    }
}

@media (max-width:587px) {
    .archiveGridItemWebsite {
        width: 30rem;
    }

    .archiveWebsiteImage {
        width: 30rem;
    }
}